import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const QuoteContainer = styled.section`
  width: 100%;
  padding: 40px 0 0;
  text-align: center;
  background: #FFF center no-repeat;
  background-size: cover;
  
  ${breakpoint('tablet')`
    padding: 80px 0;
    
    ${({ simple }) => simple && `
        padding: 0;
    `};
  `};
  
  @media (min-width: 1200px) {
    padding: 8vw 0;
  }
    
  @media (min-width: 1500px) {
    padding: 10vw 0;
  }
  
  @media (min-width: 1700px) {
    padding: 12vw 0;
  }
`;

export const InnerContainer = styled.section`
  padding: 30px;
  
  ${breakpoint('tablet')`
    flex: 1 1 40%;
    background: #FFF;
    padding: 20px;
    max-width: 45%;
    margin-left: 50px !important;
    box-shadow: 0px 0px 55px 2px rgba(0,0,0,0.22);
    
    ${({ simple }) => simple && `
        max-width: 100%;
        margin: 0 !important;
        flex: 1 1 100%;
        box-shadow: none;
        padding: 50px 20px !important;
        display: block;
    `};
  `};
`;

export const QuoteLine = withReveal(styled.h1`
  ${breakpoint('tablet')`
    font-family: "Brown-Pro-Light", AdobeBlank, Serif;
    font-size: 34px;
    font-weight: 300;
  `};
`, <Fade delay = { 100 } />);

export const Name = withReveal(styled.h3`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 700;
`, <Fade delay = { 200 } />);

export const Description = withReveal(styled.h4` 
  font-size: 12px;
   font-weight: 700;
`, <Fade delay = { 200 } />);

export const Image = withReveal(styled.img` 
  width: 100%;
  margin-top: 30px;
  margin-bottom: -5px;
`, <Fade delay = { 200 } />);

