import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import breakpoint from 'styled-components-breakpoint';
import { Fade } from 'react-reveal';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const SpecificationsContainer = styled.section`
  width: 100%;
  padding: 30px;
  background: #FFF;
`;

export const InnerContainer = styled.span`
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    margin: 30px auto;
    display: block;
    
    > div {
      column-count: 2;
    }
  `};
`;

export const Heading = styled.h1`
  ${breakpoint('tablet')`
     text-align: center;
     margin: 40px 0 30px;
     width: 100%;
  `};
`;

export const Group = styled.div`
  margin-top: 40px;
  
  ${breakpoint('tablet')`
    margin-top: 0;
    display: grid;
    -webkit-column-break-inside:avoid; -moz-column-break-inside:avoid; -o-column-break-inside:avoid; -ms-column-break-inside:avoid; column-break-inside:avoid;
  `};
`;

export const GroupHeader = styled.h4`
  font-weight: 700;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  
  ${breakpoint('tablet')`
    font-family: "Brown-Pro-Bold", AdobeBlank, Serif;
    font-size: 18px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  `}
`;

export const Item = withReveal(styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  align-items: center;
  
  ${breakpoint('tablet')`
    font-size: 15px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  `}
`, <Fade delay = { 200 } />);

export const RowLeft = styled.span`
  flex: 1;
`;
export const RowRight = styled.span`
  flex: 1;
`;
