import React from 'react';
import Button from '../button/button'

import { check } from '../../utils/stringUtils';

import {
  CenteredTextContainer,
  ContentContainer,
} from './styles';

class CenteredText extends React.Component {
  
  render() {
    const {
     text_content
    } = this.props;

    return (
      <CenteredTextContainer className="centered-text">
          <ContentContainer>{text_content.text}</ContentContainer>
      </CenteredTextContainer>
    )
  }
}


export default CenteredText;
