import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const Container = styled.div`
position: relative;
  width: 100%;
  background: #FFF;
  margin: auto;
  overflow: hidden;
`;

export const ImageOverlay = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  z-index: 1;
`;

export const TextOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 2;
`;

export const Heading = styled.h1`
  margin-bottom: 0;
  color: #FFFFFF;
`;

export const SubHeading = styled.p`
  margin-bottom: 0;
  color: #FFFFFF;
`;

