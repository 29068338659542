import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { DESKTOP_MAX_WIDTH } from '../../styles/variables'

export const RichTextContainer = styled.section`
  width: 100%;
  padding: 30px;
  background: #fff;
`

export const ContentContainer = styled.article`
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    margin: auto;
    
    column-count: ${(props) => props.columnCount};
  `};
`

export const Heading = styled.h1`
  ${breakpoint('tablet')`
    text-align: center;
  `};
`
export const Content = styled.div`
  margin-top: 40px;
  max-width: 90%;
  text-align: left;

  ${breakpoint('tablet')`
    max-width: 100%;
  `}
`
