import styled from 'styled-components';

export const BlackTheme = {
  border: '2px solid #000',
  color: '#000',
};

export const WhiteTheme = {
  border: '2px solid #FFF',
  color: '#FFF',
};

export const CTA = styled.button`
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  font-size: 14px;
  letter-spacing: 2px;
  border: ${props => props.theme.border};
  padding: 15px 20px;
  color: ${props => props.theme.color};
  text-transform: uppercase;
  cursor: pointer;
`;
