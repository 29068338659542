import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const HeroHeading = withReveal(styled.section`
	position: relative;
  width: 100%;
  min-height: 500px;
  background-size: cover;
  background-position: center;  
  background-image: ${props => `url(${props.backgroundImage})`};
  color: #FFF;
  padding: 40px 20px 40px;
  
  ${breakpoint('tablet')`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`, <Fade />);

export const InnerContainer = styled.section`
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    margin: 50px auto; 
    flex: 1 1 100%;
  `};
`;

export const VideoContainer = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Heading = withReveal(styled.h1`
  ${breakpoint('tablet')`
    font-size: 64px;
    margin-bottom: 0;
  `};
`, <Fade delay = { 100 } />);

export const Introduction = withReveal(styled.p`
  margin-top: 40px;
  max-width: 90%;

  ${breakpoint('tablet')`
    max-width: 500px;
  `};
`, <Fade delay = { 200 } />);

export const CTA = withReveal(styled.div`
  margin-top: 40px;
`, <Fade delay = { 300 } />);

export const TopImage = withReveal(styled.div`
  display: block;
  max-width: 200px;
  margin: 0 auto 30px;
  
  img {
    width: 100%;
  }
  
  ${breakpoint(`tablet`)`
    
  `};
`, <Fade />);
