import React, { Fragment } from 'react'
import { ThemeProvider } from 'styled-components'

import { CTA, BlackTheme, WhiteTheme } from './styles';

const Button = ({ text, onClickHandler, black = false }) => (
  <ThemeProvider theme = { black ? BlackTheme : WhiteTheme }>
    <CTA onClick = { onClickHandler }>{ text }</CTA>
  </ThemeProvider>
);

export default Button;
