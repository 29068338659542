import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const CenteredTextContainer = styled.div`
  width: 100%;
  padding: 50px;
  background: #FFF;
  margin: auto;
  max-width: 900px;
`;

export const ContentContainer = styled.p`  
  text-align: center;
`;
