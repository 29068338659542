import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

/*
 * Themes.
 * -
 * These are determined by the 'layout' value of the component.
 */
export const Dark = {
  background: "#000",
  color: "#FFF",
  imagePadding: "0",
  subtextColor: "#FFF",
  titleColor: "#FFF",
  titleSize: "22px",
  titleMargin: "0 0 20px",
  titleWeight: "300",
};

export const Light = {
  background: "#FFF",
  color: "#000",
  imagePadding: "20px 20px",
  subtextColor: "#000",
  titleColor: "#000",
  titleSize: "20px",
  titleMargin: "20px 0",
  titleWeight: "700",
};

/*
 * Styles
 */
export const ListContainer = styled.section`
  width: 100%;
  background: ${props => props.theme.background};
  padding: 30px;
  color: ${props => props.theme.color};
  
  ${breakpoint('tablet')`
    padding: 100px 30px 50px;
    text-align: center !important;
  `};
`;

export const Introduction = styled.p`
  ${breakpoint('tablet')`    
      font-size: 20px;
      max-width: 500px;
      margin: 0 auto;
      display: block;
      margin-top: 30px;
  `};
`;

export const ListTitle = styled.h1`
  display: block;
  margin-bottom: 40px;
  
  ${({ split }) => split && `
    text-align: left;
    margin-bottom: 10px;
  `}
  
  ${breakpoint('tablet')`
    text-align: center;
  `};
`;

export const ItemsContainer = styled.div`
  ${({ split }) => split && `
    display: flex;
    flex-wrap: wrap;
    
    div {
      &:nth-child(odd) {
        margin-right: 10px;
      }
    }
  `}
  
  ${breakpoint('tablet')`
    display: flex;
    max-width: ${DESKTOP_MAX_WIDTH};
    flex-wrap: wrap;
    margin: 50px auto; 
    justify-content: center;
    padding-left: 8px;
    
    div {
      &:nth-child(odd) {
        margin-right: 0px;
      }
    }
  `}
`;

export const ItemContainer = styled.div`
  ${({ split }) => split && `
    width: 49%;
    max-width: 49%;
    display: inline-block;
    text-align: left !important;
    padding-right: 15px;
    margin-top: 10px;
    flex: 1 0 48%;
    
    div {
      //text-align: left;
      margin-bottom: 0 !important;
    }
    
    img {
      margin: 0;
    }
    
    img {
        max-width: 50% !important;
    }
  `}
  
  ${breakpoint('tablet')`
    width: 33%;
    flex: 0 0 24%;
    text-align: center !important;
    
    div {
      display: inline-block;
    }
    
    img {
        max-width: 100% !important;
        height: 60px;
    }
  `}
`;

export const Item = styled.div`
  text-align: center;
  margin: 0 0 50px;
  
  ${({ split }) => split && `
    width: 49%;
    margin-bottom: 0;
  `}
  
`;

export const ImageContainer = styled.div`
  height: 120px;
  
  ${breakpoint('tablet')`
      height: 110px !important;
  `}
  
  img {
    object-fit: contain;
    height: 100%;
    
    // ${breakpoint('tablet')`
    //   height: 60px;
    // `}'
  }
`;

export const Title = styled.h2`
  margin: ${props => props.theme.titleMargin};
  color: ${props => props.theme.titleColor};
  font-size: ${props => props.theme.titleSize};
  font-weight: ${props => props.theme.titleWeight};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 26px;
  margin: 0;
  color: ${props => props.theme.subtextColor};
  display: block !important;

  text-align: ${props => props.align};
  
  p, h5 {
    line-height: 26px !important;
    font-size: 14px !important;
  }
`;

Description.defaultProps = {
  align: 'left'
}

export const Image = styled.img`
  margin: 0 auto 20px;
`;
