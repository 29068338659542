import React, { Fragment } from 'react';
import { navigate } from "gatsby";
import Media from 'react-media';
import {window} from 'browser-monads';

import {
  HeroHeadingWithSubNavigation,
  Heading,
  Dropdown,
  Option,
  DropdownWrapper,
} from './styles';
import { isWide } from '../../utils/responsive';
import { SubNavigation, SubNavigationItem } from '../machine-heading/styles';
import { Image, ImageContainer } from '../basic-content-container/styles';

class PageHeroHeadingSubNavigation extends React.Component {
  header;

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    navigate(evt.target.value);
  }

  componentDidMount () {
     if(typeof document !== 'undefined') {
       this.header = document.getElementsByTagName('header')[0];
       this.header.classList.add('absolute');
     }
  }

  componentWillUnmount () {
    if(this.header) {
      this.header.classList.remove('absolute');
    }
  }

  renderDropdown(links) {
    return (
      <DropdownWrapper>
        <Dropdown onChange = { this.onChange }>
          { links.map(link => <Option key = { link.link_title.text.toLowerCase() } value = { link.link_url.text }>{ link.link_title.text.toUpperCase() }</Option>) }
        </Dropdown>
      </DropdownWrapper>
    );
  }

  renderLinks(links) {
    return (
      <SubNavigation>
        { links.map((link, index) => (
          <SubNavigationItem
            active = { typeof window !== 'undefined' ? (window.location.pathname.substring(3) === `${link.link_url.text}/` || window.location.pathname.substring(3) === `${link.link_url.text}`) : (index === 0 && true)  }
            onClick = { this.onChange }
            value = { link.link_url.text }
            key = { link.link_url.text }
          >
            { link.link_title.text }
          </SubNavigationItem>
        )) }
      </SubNavigation>
    )
  }

  render() {
    const {
      heading,
      background_image,
      body
    } = this.props;

    let links = body[0].items;

    return (
      <HeroHeadingWithSubNavigation backgroundImage = { background_image.url }>
        <div>
          { body.length > 0 && (
          <div>
            <Media
              query = "(min-width:768px)"
              render = { () => {
                if(links[0].link_title.text === "e'Connect") {
                  links = links.reverse();
                }

                return this.renderLinks(links);
              } }
            />
          </div>
) }
          { body.length > 0 && (
          <div>
            <Media
              query = "(max-width:767px)"
              render = { () => {
                  return this.renderDropdown(links)
                } }
            />
          </div>
) }
        </div>
        <Heading>{ heading.text }</Heading>
      </HeroHeadingWithSubNavigation>
    )
  }
}

export default PageHeroHeadingSubNavigation;
