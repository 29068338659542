import React from 'react';
import Button from '../button/button'

import { check } from '../../utils/stringUtils';

import { Container, ImageOverlay, TextOverlay, Heading, SubHeading } from './styles';

class ImageOverlayTitle extends React.Component {
  
  render() {
	const { image, heading, subheading } = this.props;

    return (
      <Container>
		  {image && <ImageOverlay 
		  	src = { image && image.url ? image.url : null }
			alt = { image.alt } />}
		<TextOverlay>
			<Heading>{heading.text}</Heading>
			<SubHeading>{subheading}</SubHeading>
		</TextOverlay>
	  </Container>
    )
  }
}


export default ImageOverlayTitle;
