import PageHeroHeading from '../components/hero-heading/heroHeading';
import BasicContentContainer from '../components/basic-content-container/basicContentContainer';
import PageHeroHeadingSubNavigation from '../components/hero-heading-sub-nav/heroHeadingSubNav';
import List from '../components/list/list';
import Gallery from '../components/gallery/gallery';
import Quote from '../components/quote/quote';
import RichText from '../components/rich-text/richText';
import Specifications from '../components/specifications/specifications';
import TechnicalComparison from '../components/technical-comparison/technicalComparison';
import VideoEmbed from '../components/video-embed/videoEmbed';
import CenteredText from '../components/centered-text/centeredText';
import ImageOverlayTitle from '../components/image-overlay-title/imageOverlayTitle';

const ComponentMappings = [
  {
    prismicKey: 'page_hero_heading',
    component: PageHeroHeading,
  },
  {
    prismicKey: 'page_hero_heading_with_sub_navigation',
    component: PageHeroHeadingSubNavigation,
  },
  {
    prismicKey: 'basic_content_container',
    component: BasicContentContainer,
  },
  {
    prismicKey: 'list',
    component: List,
  },
  {
    prismicKey: 'gallery',
    component: Gallery,
  },
  {
    prismicKey: 'quote',
    component: Quote,
  },
  {
    prismicKey: 'rich_text',
    component: RichText,
  },
  {
    prismicKey: 'specifications',
    component: Specifications,
  },
  {
    prismicKey: 'technical_comparison',
    component: TechnicalComparison,
  },
  {
    prismicKey: 'video_embed',
    component: VideoEmbed,
  },
  {
    prismicKey: 'centered_text',
    component: CenteredText,
  },
  {
    prismicKey: 'image_overlay_title',
    component: ImageOverlayTitle,
  },
];

export default ComponentMappings;
