import React, { Fragment } from 'react';
import { navigate } from "gatsby";
import Media from 'react-media';

import { VideoSection, Heading, Description, VideoFrame, VideoText, Wrapper } from './styles';
import { isWide } from '../../utils/responsive';
import Button from '../button/button';
import { onClickHandler } from '../../utils/url';


class VideoEmbed extends React.Component {

	getBGColor(bg) {
		if(bg && bg.toLowerCase() === 'white') return '#FFF';
		if(bg && bg.toLowerCase() === 'black') return '#000';
		return '#CCC';
	}

	getLayout(layout) {
		if(!layout || layout.toLowerCase() === 'video center')
			return false;
		return true;
	}

	videoLeft(layout) {
		if(layout && layout.toLowerCase() === 'video left')
			return true;
		
		return false;
	}

	getTextColor(bg) {
		if(bg && bg.toLowerCase() === 'black') return '#FFF';
		return null;
	}

	getButton(label, link, bg) {
		
		if(label && label.length > 0 && link && link.url && link.url.length > 0) {
			return <Button text={label} onClickHandler={() => onClickHandler(link.url)} black={ bg === '#000' } />;
		}

		return null;
	}

	render() {
		const {heading, embedcode, background, layout, content, button_label, button_link} = this.props;
        // console.log("VideoEmbed -> render -> button_link", button_label, button_link);
		
		const bg = this.getBGColor(background);
		const color = this.getTextColor(background);
		const isFlex = this.getLayout(layout);
		const videoLeft = this.videoLeft(layout);
		const button = this.getButton(button_label, button_link, background);
		//console.log(content);

		return (
			<VideoSection background={bg}>
				<Wrapper flexLayout={isFlex}>
					<VideoText flexLayout={isFlex} style={{color}}>
						{heading && heading.text && <Heading>{heading.text}</Heading>}
						{content && content.html && <Description dangerouslySetInnerHTML={{ __html: content.html }}></Description>}
						{button}
					</VideoText>
					{embedcode && embedcode.html && <VideoFrame videoLeft={videoLeft} flexLayout={isFlex} dangerouslySetInnerHTML={{ __html: embedcode.html }} />}
				</Wrapper>
			</VideoSection>
		)
	}
}

export default VideoEmbed;
