import React  from 'react';
import { navigate } from 'gatsby';
import SVG from 'react-inlinesvg';

import Media from 'react-media';
import {
  ComparisonContainer,
  ContentContainer,
  FeatureContainer,
  Feature,
  ResultsContainer,
  Results,
  ResultItem,
  Table,
  Dropdown,
  Label,
  Row,
  Rows,
  Labels,
  InnerContainer,
} from './styles'
import { flatten } from '../../utils/selectors';

class TechnicalComparison extends React.Component {
  constructor(props) {
    super(props);

    const setModel = props.body[0].primary.fixed_model.text;
    const items = flatten(props.body[0].items.map(item => 
							{
								if(item.present_in && item.present_in.text)
									return item.present_in.text.split(',')
									return [];
							})
					);

    const reduced = items.filter(item => item !== "").map(item => this.strip(item));
    const modelsToCompare = Array.from(new Set(reduced)).filter(model => model !== setModel);

    this.state = {
      selectedModel: modelsToCompare[0],
      modelsToCompare
    };
  }

  strip = (str) => {
    return str.replace(/^\s+|\s+$/g, '');
  };

  checkFeature = (feature, modelToCompare) => {
    const present = <SVG src = "/images/tick.svg" />;
    const notPresent = <SVG src = "/images/X.svg" />;
	let models = [];
	if(feature && feature.present_in && feature.present_in.text)
		models = feature.present_in.text.split(',');

    const result = models.filter(model => {
      return model.trim() === modelToCompare.trim();
    });

    return result.length > 0 ? present : notPresent;
  };

  onDropdownChange = evt => this.setState({ selectedModel: evt.target.value });

  generateRow = (feature, setModel, selectedModels) => {
    return (
      <Row key = { feature.feature.text }>
        <Feature>{ feature.feature.text }</Feature>
        <ResultItem key = { `resultFixed${feature.feature.text}` }>{ this.checkFeature(feature, setModel) }</ResultItem>
        { selectedModels.map(model => <ResultItem key = { `${model}${feature.feature.text}` }>{ this.checkFeature(feature, model) }</ResultItem>) }
      </Row>
    )
  };

  renderDropdown = () => {
    const {
      modelsToCompare
    } = this.state;

    return (
      <Dropdown onChange = { (evt) => this.onDropdownChange(evt) }>
        { modelsToCompare.map(model => <option key = { model }>{ model }</option>) }
      </Dropdown>
    )
  }

  render() {
    const {
      heading,
      introduction,
      body,
    } = this.props;

    const {
      selectedModel,
      modelsToCompare,
    } = this.state;

    const features = body[0].items;
    const setModel = body[0].primary.fixed_model.text;

    return (
      <ComparisonContainer>
        <ContentContainer>
          <InnerContainer>
            <h1>{ heading.text }</h1>
            <p>{ introduction.text }</p>
          </InnerContainer>
          <Table>
            <Media query = "(max-width: 768px)">
              <Labels simple = { modelsToCompare.length === 1 }>
                <Label>{ setModel }</Label>
                { modelsToCompare.length > 1 ? this.renderDropdown() : <Label>{ modelsToCompare[0] }</Label> }
              </Labels>
            </Media>
            <Rows>
              <div>
                <Media query = "(min-width: 768px)">
                  <div>
                    <Row>
                      <Feature>{ ' ' }</Feature>
                      <ResultItem key = { `resultFixed${setModel}` }>{ setModel }</ResultItem>
                      { modelsToCompare.map(model => <ResultItem key = { `${model}` }>{ model }</ResultItem>) }
                    </Row>
                    {
                      features.map(feature => this.generateRow(feature, setModel, modelsToCompare))
                    }
                  </div>
                </Media>
              </div>
              <div>
                <Media query = "(max-width: 768px)">
                  <div>
                    {
                      features.map(feature => this.generateRow(feature, setModel, [ selectedModel ]))
                    }
                  </div>
                </Media>
              </div>
            </Rows>
          </Table>
        </ContentContainer>
      </ComparisonContainer>
    )
  }
}

export default TechnicalComparison;
