import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const HeroHeadingWithSubNavigation = withReveal(styled.section`
  width: 100%;
  background-size: cover;
  background-position: center;  
  background-image: ${props => `url(${props.backgroundImage})`};
  color: #FFF;
  padding: 100px 20px 20px;
  
  ${breakpoint('tablet')`

    height: 100vh;
    justify-content: center;
    align-items: center;
  `};
`, <Fade />);

export const Heading = withReveal(styled.h1`
  text-align: center;
  margin: 50px 0;
  
  ${breakpoint('tablet')`
    max-width: 90%;
    margin: calc(50vh - 210px) auto 0;
    font-size: 64px;
    height: 200px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
  `};
`, <Fade delay = { 100 } />);

export const Dropdown = withReveal(styled.select`
  border-radius: 4px;
  background: 0;
  width: 100%;
  padding: 10px;
  border: 1px solid #FFF;
  color: #FFF;
  display: block;
  letter-spacing: 3px;
`, <Fade delay = { 100 } />);

export const DropdownWrapper = styled.div`
  position: relative;
  
  ${breakpoint('tablet')`
    display: none;
  `};
  
  &:after {
    content: ' ';
    background: url(${props => props.isBlack ? '/images/dropDown.svg' : '/images/dropDownWhite.svg'}) no-repeat center;
    width: 10px;
    height: 10px;
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -5px;
    pointer-events: none;
  }
`;

export const Option = styled.option`
  color: #FFF;
`;
