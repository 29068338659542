import React from 'react';
import PropTypes from 'prop-types';

import {
  QuoteContainer,
  InnerContainer,
  QuoteLine,
  Name,
  Description,
  Image
} from './styles';
import { isWide } from '../../utils/responsive';

class Quote extends React.Component {
  static propTypes = {
    description: PropTypes.object,
    desktop_image: PropTypes.object,
    mobile_image: PropTypes.object,
    name: PropTypes.object,
    quote: PropTypes.object
  };

  render() {
    const {
      quote,
      name,
      description,
      mobile_image,
      desktop_image,
    } = this.props;

    const background = isWide ? desktop_image.url : '';

    return name.text && quote.text ? (
      <QuoteContainer className = "quote" style = { { backgroundImage: `url(${background})` } } simple = { desktop_image.url === null }>
        <InnerContainer simple = { desktop_image.url === null }>
          <QuoteLine>{ quote.text }</QuoteLine>
          <Name>{ name.text }</Name>
          <Description>{ description.text }</Description>
        </InnerContainer>
        { !isWide && <Image src = { mobile_image.url } alt="Eversys image" /> }
      </QuoteContainer>
    ) : null;
  }
}

export default Quote;
