import React from 'react';
import Button from '../button/button'

import { check } from '../../utils/stringUtils';

import {
  SpecificationsContainer,
  InnerContainer,
  Heading,
  Item,
  RowLeft,
  RowRight,
  Group,
  GroupHeader,
} from './styles';

class Specifications extends React.Component {
  renderItem(item) {
    const {
      primary,
      items
    } = item;
    return (
      <Group key = { primary.group_title.text }>
        <GroupHeader>{ primary.group_title.text }</GroupHeader>
        {
          items.map(item => (
            <Item key = { item.specification_name.text }>
              <RowLeft>{ item.specification_name.text }</RowLeft>
              <RowRight>{ item.specification_text.text }</RowRight>
            </Item>
          ))
        }
      </Group>
    )
  }
  render() {
    const {
      title,
      body,
    } = this.props;

    return (
      <SpecificationsContainer>
        <InnerContainer>
          { check(title) && <Heading>{ title.text }</Heading> }
          <div>{ body.map(itemContainer => this.renderItem(itemContainer)) }</div>
        </InnerContainer>
      </SpecificationsContainer>
    )
  }
}

export default Specifications;
