import { navigate } from 'gatsby';

export const onClickHandler = url => {
  if(url.includes('eversys.nrthpoint.com') || url.includes('eversys.com')) {
    const urlObj = new URL(url);
    const localeCheck = urlObj.pathname.substring(0, 3);

    if(localeCheck === '/en' || localeCheck === '/de' || localeCheck === '/fr' || localeCheck === '/cn') {
      navigate(urlObj.pathname.substring(3, urlObj.pathname.length));
    } else {
      navigate(urlObj.pathname);
    }

  } else {
    window && window.open(url, '_blank');
  }
};
