import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const MachineHeading = styled.section`
  position:relative;
  width: 100%;
  background-size: cover;
  background-position: center;  
  background-image: ${props => `url(${props.backgroundImage})`};
  color: #FFF;
  padding: 80px 20px 180px;
  overflow: hidden;
  
  ${({ isBlack }) => isBlack && `
      h1, select, option, button, p {
        color: #000 !important;
      }
      
      h1, select, option, button, p {
        border-color: #000 !important;
      }
  `}
  
  ${({ useLargeHeader, extraPadding }) => (useLargeHeader || extraPadding) && `
     padding: 80px 20px 260px;
  `}
  
  ${breakpoint('tablet')`
    padding-bottom: 230px;
    padding-top: 120px;
    
    ${({ useLargeHeader, extraPadding }) => (useLargeHeader || extraPadding) && `
       padding: 80px 20px 400px;
    `};
  `};
  
  ${breakpoint('tablet')`
      ${({ overallTitle }) => (overallTitle === "e6" || overallTitle === "e4" || overallTitle === "e2") && `
          padding: 80px 20px 400px !important;
      `};
   `};

`;

/*
 * Type Navigation
 */
export const SubNavigation = withReveal(styled.span`
  margin: 0 20px 40px;
  display: flex;
  justify-content: center;

  &:last-of-type {
    text-transform: uppercase !important;
  }
`, <Fade />);

export const SubNavigationItem = styled.button`
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  color: #FFF;
  padding-bottom: 5px;
  margin-right: 20px;
  cursor: pointer;

  ${breakpoint('tablet')`
      font-size: 18px;
	  margin-right: 30px;
	  letter-spacing: 3px;
   `};

  &:last-of-type {
  margin-right: 0;
  }

  ${({ active }) => active && `
    border-bottom: 2px solid #FFF;
    margin-bottom: -2px;
  `}
`;

/*
 * Heading
 */

export const HeadingContainer = withReveal(styled.span`
  display: block;
`, <Fade delay = { 100 } />);

export const Heading = withReveal(styled.h1`
  text-align: center;
  margin: 40px 0 0;
  font-size: 12vw;
  font-weight: 700;
`, <Fade delay = { 100 } />);

export const ColourHeading = withReveal(styled.h3`
  text-align: center;
  margin: 0 0;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 100;
`, <Fade delay = { 100 } />);

/*
  Dropdown
 */

export const Dropdown = withReveal(styled.select`
  border-radius: 4px;
  background: 0;
  width: 100%;
  padding: 10px;
  border: 1px solid #FFF;
  color: #FFF;
  display: block;
  letter-spacing: 3px;
  text-transform: uppercase;
`, <Fade delay = { 100 } />);

export const Option = styled.option`
  color: #FFF;
`;

/*
  Machine Image
 */
export const MachineImageContainer = styled.span`

`;

export const MachineImage = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 80px;

    ${({ useLargeHeader }) => useLargeHeader && `
        padding: 0;
        max-width: 140%;
        left: -20%;
        
        ${breakpoint('tablet')`
          //left: 0;
        `};
  `}

    ${breakpoint('tablet')`
      margin: 0 auto;
      left: 0;
    `};
`;

export const ColourOptionsContainer = styled.span`
  position:absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  flex-direction: column;

  ${breakpoint('tablet')`
    bottom: 20px;
  `};
`;

export const ColourOption = styled.button`
  width: 24px;
  height: 24px;
  margin-bottom: 5px;

  ${({ active }) => active && `
    img {
      border: 2px solid #FFF !important;
    }
  `}

  img {
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .5);
    max-width: 100%;
  }

  ${breakpoint('tablet')`
    width: 40px;
    height: 40px;
    margin: 10px;
    display: flex;
    align-items: center;
    
    span {
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #FFF;
      margin-left: 10px;
      font-size: 16px;
    }  
  `};
`;

export const BackgroundImageContainer = styled.span`
   z-index: -1;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
`;

export const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit:cover;
`;

export const ListWrapper = styled.span`
  button {
    text-transform: uppercase !important;
    font-size: 14px;
    margin-right: 20px;

    ${breakpoint('tablet')`
      margin-right: 30px;
    `};
  }
`;
