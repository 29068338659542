import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { check, checkImage } from '../../utils/stringUtils';

import {
  Item,
  ItemContainer,
  Title,
  Description,
  Image,
  ListContainer,
  ListTitle,
  ItemsContainer,
  Light,
  Dark,
  ImageContainer,
  Introduction,
} from './styles';

const LIGHT = 'Light';

class List extends React.Component {
  static renderListItem(data, number_of_columns) {
    const {
      image,
      item_title,
      item_description,
      item_description_rich,
    } = data;

    const title = check(item_title);
    const content = item_description_rich.html === null ? item_description.html : item_description_rich.html;

    return (
      <ItemContainer className = "listItemContainer" split = { number_of_columns === 'Double' } key = { `${title}${Math.random()}` }>
        <Item className = "listItem">
          { checkImage(image) && <ImageContainer><Image src = { image.url } /></ImageContainer> }
          { check(item_title) && <Title>{ item_title.text }</Title> }
          <Description align='center' dangerouslySetInnerHTML = { { __html:content } } />
        </Item>
      </ItemContainer>
    );
  }

  constructor(props) {
    super(props);

    const { style } = props;
    let theme;

    if (style === LIGHT) {
      theme = Light;
    } else {
      theme = Dark;
    }

    this.state = {
      theme,
    };
  }

  render() {
    const {
      title,
      introduction,
      body,
      number_of_columns
    } = this.props;

    const items = body.length > 0 ? body[ 0 ].items : [];

    return (
      <ThemeProvider theme = { this.state.theme }>
        <ListContainer className = "list">
          { check(title) && <ListTitle split = { number_of_columns === 'Double' }>{ title.text }</ListTitle> }
          { check(introduction) && <Introduction>{ introduction.text }</Introduction> }
          <ItemsContainer split = { number_of_columns === 'Double' }>
            { items.map(item => List.renderListItem(item, number_of_columns)) }
          </ItemsContainer>
        </ListContainer>
      </ThemeProvider>
    );
  }
}

export default List;
