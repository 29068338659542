import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const VideoSection = styled.section`
	width: 100%;
	color: #000;
	background-color: ${props => props.background};
	padding: 50px 20px 50px;
	${breakpoint('tablet')`
		padding: 100px 50px 50px;
  	`};
`;

export const Wrapper = styled.div`
	max-width: 1260px;
	margin: 0 auto;
	flex-direction: column;

	${({ flexLayout }) => flexLayout && `
		display: flex;
	`}

	${breakpoint('tablet')`
		flex-direction: row;
  	`};
`;


export const Heading = styled.h1`
	margin: 0 0 20px 0;
	${breakpoint('tablet')`
		margin: 0 0 50px 0;
  	`};
`;

export const Description = styled.div`
	margin: 0;
	${breakpoint('tablet')`
		margin: 50px 0;
	`};
`;

export const VideoFrame = styled.div`
	text-align: center;
	margin: 50px 0;
	order: 3;

	${({ flexLayout }) => flexLayout && `
		margin: 0 25px;
	`}

	${breakpoint('tablet')`
		${({ videoLeft }) => videoLeft && `
			order: 1 !important;
		`}
	`};

	
`;

export const VideoText = styled.div`
	text-align: center;
	order: 2;

	${breakpoint('tablet')`
		margin: 0 50px;
		${({ flexLayout }) => flexLayout && `
			text-align: left;
			margin: 0 25px;
		`}
	`};
	
	
	
`;
