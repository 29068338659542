import React from 'react';
import Button from '../button/button'

import { check } from '../../utils/stringUtils';

import {
  RichTextContainer,
  Heading,
  Content,
  ContentContainer,
} from './styles';

class RichText extends React.Component {
  render() {
    const {
      title,
      content,
      column_count,
    } = this.props;

    return (
      <RichTextContainer className = "richTextContainer">
        <ContentContainer columnCount = { column_count }>
          { check(title) && <Heading>{ title.text }</Heading> }
          {content && content.html && <Content dangerouslySetInnerHTML = { { __html:content.html } } />}
        </ContentContainer>
      </RichTextContainer>
    )
  }
}

RichText.defaultProps = {
  column_count: 1,
};

export default RichText;
