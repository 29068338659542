import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Media from 'react-media';
import Button from '../button/button';
import { isWide } from '../../utils/responsive';

import {
  Container,
  Heading,
  Content,
  Image,
  CTA,
  ContentContainer,
  ImageContainer,
  InnerContainer,
  BasicLayoutLeftTheme,
  BasicLayoutTheme,
  FullBackgroundLayoutTheme,
  FullBackgroundCTALayoutTheme,
  InvertedBasicLayoutTheme, BasicGreyLayoutTheme,
} from './styles';
import { onClickHandler } from '../../utils/url';

export const BASIC_LAYOUT = "Basic (right aligned image)";
export const BASIC_GREY_LAYOUT = "Basic - Grey (right aligned image)";
export const BASIC_INVERTED_LAYOUT = "Basic - Inverted (right aligned image)";
export const FULL_BACKGROUND_LAYOUT = "Full Background Image (right aligned content)";
export const FULL_BACKGROUND_WITH_CTA_LAYOUT = "Full Background w/ CTA (left aligned content)";
export const BASIC_INVERTED_LAYOUT_LEFT = "Basic - Inverted (left aligned image)";

class BasicContentContainer extends React.Component {
  constructor(props) {
    super(props);

    const { layout } = props;
    let theme;

    if(layout === BASIC_LAYOUT) {
      theme = BasicLayoutTheme;
    } else if (layout === BASIC_GREY_LAYOUT) {
      theme = BasicGreyLayoutTheme;
    } else if (layout === FULL_BACKGROUND_LAYOUT) {
      theme = FullBackgroundLayoutTheme;
    } else if (layout === BASIC_INVERTED_LAYOUT) {
      theme = InvertedBasicLayoutTheme;
    } else if(layout === BASIC_INVERTED_LAYOUT_LEFT){
      theme = BasicLayoutLeftTheme;
    } else {
      theme = FullBackgroundCTALayoutTheme;
    }

    this.state = {
      theme: theme,
    };
  }

  renderBasicElements(imageLeft) {
    
    const {
      heading,
      content,
      image,
      desktop_image,
      link,
      link_title,
    } = this.props;

    const {
      theme
    } = this.state;

    const correctImage = isWide ? desktop_image : image;
    const simpleElement = link_title.text !== "" && heading.text !== "" && content.text === "";

    return (
      <Fragment>
        <ContentContainer simpleElement = { simpleElement } imageLeft={imageLeft}>
          { heading.text && <Heading>{ heading.text }</Heading> }
          { content.html && <Content dangerouslySetInnerHTML = { { __html:content.html } } /> }
          { link && link_title.text && (
            <CTA>
              <Button text = { link_title.text } onClickHandler = { () => onClickHandler(link.url) } black = { theme.background !== '#000' } />
            </CTA>
          ) }
        </ContentContainer>
        { correctImage && correctImage.url && (
        <ImageContainer className = "imageContainer">
          <Media
            query = "(min-width:768px)"
            render = { () => <Image src = { desktop_image.url } alt="Desktop image" /> }
          />
          <Media
            query = "(max-width:767px)"
            render = { () => <Image src = { image.url } alt="Mobile image" /> }
          />
        </ImageContainer>
      ) }
      </Fragment>
    )
  }

  renderBackgroundElements() {
    const {
      heading,
      content,
      image,
      desktop_image,
      layout,
    } = this.props;

    const correctImage = isWide ? desktop_image : image;

    return (
      <Fragment>
        <ContentContainer layout = { layout }>
          { heading.text && <Heading>{ heading.text }</Heading> }
          <Content>{ content.text }</Content>
        </ContentContainer>
        { !isWide && (
          <ImageContainer>
            <Image src = { correctImage.url } alt="Eversys image" />
          </ImageContainer>
        ) }
      </Fragment>
    )
  }

  renderBackgroundCtaElements() {
    const {
      heading,
      content,
      image,
      link,
      link_title,
      layout
    } = this.props;

    const simpleElement = link_title.text !== "" && heading.text !== "" && content.text === "";

    return (
      <Fragment>
        <ContentContainer layout = { layout } simpleElement = { simpleElement }>
          { heading.text && <Heading>{ heading.text }</Heading> }
          { content.text && <Content>{ content.text }</Content> }
          { link && link_title.text && (
            <CTA>
              <Button text = { link_title.text } onClickHandler = { () => onClickHandler(link.url) } black />
            </CTA>
          ) }
        </ContentContainer>
        { !isWide && (
          <ImageContainer>
            <Image src = { image.url } alt="Eversys image" />
          </ImageContainer>
        ) }
      </Fragment>
    )
  }

  getLayout() {
    const {
      layout,
    } = this.props;

    /*
     * TODO: Make element rendering selection cleaner.
     */
    if(layout === BASIC_LAYOUT || layout === BASIC_INVERTED_LAYOUT || layout === BASIC_GREY_LAYOUT || layout === BASIC_INVERTED_LAYOUT_LEFT) {
      const imageLeft = (layout === BASIC_INVERTED_LAYOUT_LEFT);
      return this.renderBasicElements(imageLeft);
    } else if (layout === FULL_BACKGROUND_LAYOUT) {
      return this.renderBackgroundElements();
    } else if (layout === FULL_BACKGROUND_WITH_CTA_LAYOUT) {
      return this.renderBackgroundCtaElements();
    }
  }

  render() {
    const {
      layout,
      image,
      desktop_image,
      content,
      heading,
      link,
      link_title,
    } = this.props;

    //console.log(layout);

    const textCheck = content && content.text === "";
    const simpleElement = link_title.text !== "" && heading.text !== "" && content.text === "";

    return (
      <Fragment>
        <ThemeProvider theme = { this.state.theme }>
          <Container backgroundImage = { layout === FULL_BACKGROUND_LAYOUT || layout === FULL_BACKGROUND_WITH_CTA_LAYOUT ? `url(${desktop_image.url})` : "" } layout = { layout } forceColumn = { textCheck } className = "container">
            <InnerContainer simpleElement = { simpleElement } layout = { layout } forceColumn = { textCheck } className = "innerContainer">
              { this.getLayout() }
            </InnerContainer>
          </Container>
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default BasicContentContainer;
