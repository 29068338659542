import React from 'react';
import styled from 'styled-components'
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const ComparisonContainer = styled.article`
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  padding: 30px;
  background: #FFF;
  
  ${breakpoint('tablet')`
    padding: 60px 0;
  `};
`;

export const ContentContainer = styled.article`  
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    margin: auto;
    display: flex;
    transform: translateX(34px);
  `};
`;

export const InnerContainer = styled.article`  
  ${breakpoint('tablet')`
    flex: 1;
    padding-right: 20px;
    
    h1 {
      margin-bottom: 10px;
    }
  `};
`;

export const Title = styled.h3`
  margin: 5px 0;
`;

export const Table = styled.div`
  width: 100%;
  padding-top: 0;
  margin-top: 0;
  
  ${breakpoint('tablet')`
    flex: 2;
  `};
`;

export const FeatureContainer = styled.div`
  flex: 2;
`;

export const Feature = styled.span`
  flex: 2;
  display: block;
  padding: 15px 20px 15px 0;
`;

export const ResultsContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const Results = styled.div`
  margin-right: 40px;
  margin-top: -55px;
`;

export const ResultItem = styled.div`
  flex: 1;
  padding: 15px 0;
  text-align: center;
  font-family: "Brown-Pro-Bold", AdobeBlank, serif;
  
  svg {
    width: 20px;
    margin-left:-40px;
  }
  
  ${breakpoint('tablet')`
    padding: 10px 0;
  `};
`;

export const Dropdown = styled.select`
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 17px;
  
  font-size: 17px;
  width: 83px;
  
  ${breakpoint('tablet')`
    width: 112px;
    margin-right:68px;
  `};
`;

export const Label = styled.p`
  display: block;
  margin: 0 !important;
`;

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  
  ${breakpoint('tablet')`
    &:first-of-type {
      border: none;
      
      div {
        margin-right: 15px;
        
        &:first-of-type {
          margin-right: 10px;
        }
        
        &:last-of-type {
          margin-right: 20px;
        }
      }
    }
  `}
`;

export const Rows = styled.div`
  display: block;
`;

export const Labels = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  p {
    width: 40px;
    text-align: center;
    
    &:first-of-type {
      margin-right: -30px;
      text-align: center;
      
      ${breakpoint('tablet')`
        margin-right: 72px;
        text-align: center;
        width: 100px;
      `};
    }
  }  
  
  ${({ simple }) => simple && `
    p {
      margin: 0 40px;
      width: 40px;
      text-align: center;

      &:first-of-type {
        width: 50px;
      }
      
      &:last-of-type {
        width: 106px;
      }
    }
  `};
  
  ${breakpoint('tablet')`
    ${({ simple }) => simple && `
      p {
       width: 160px !important;
    }`}
  `}
`;
