import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types';
import { renderComponent } from './componentRender'

class DynamicContentContainer extends React.Component {
  components = {};

  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <Fragment>
        {
          data.map(({ component }) => renderComponent(component))
        }
      </Fragment>
    );
  }
}

DynamicContentContainer.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DynamicContentContainer
