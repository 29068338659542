import React from 'react'
import Button from '../button/button'

import {
  HeroHeading,
  Heading,
  Introduction,
  CTA,
  InnerContainer,
  TopImage,
  VideoContainer,
} from './styles'
import { checkImage } from '../../utils/stringUtils'
import { onClickHandler } from '../../utils/url'

class PageHeroHeading extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      heading,
      introduction,
      link,
      link_text,
      background,
      video_background,
      top_image,
    } = this.props

    return (
      <HeroHeading backgroundImage={background && background.url ? background.url : null}>
		  {video_background && video_background.url && video_background.kind !== 'image' && 
		  	<VideoContainer autoPlay muted playsInline loop>
				<source src={video_background.url} type="video/mp4" />
			</VideoContainer>
		  }
        <InnerContainer>
          {checkImage(top_image) && (
            <TopImage>
              <img src={top_image.url} alt="Top Image" />
            </TopImage>
          )}
          <Heading>{heading && heading.text ? heading.text : ''}</Heading>
          <Introduction>
            {introduction && introduction.text ? introduction.text : ''}
          </Introduction>
          <CTA>
            {/* <Button text = { link && link_text && link_text.text ? link_text.text : '' } black = { false } onClickHandler = { () => onClickHandler(link.url) } /> */}
            <Button
              text={link_text.text}
              black={false}
              onClickHandler={() => onClickHandler(link.url)}
            />
          </CTA>
        </InnerContainer>
      </HeroHeading>
    )
  }
}

export default PageHeroHeading
